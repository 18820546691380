import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PageHandler from '@jpp/shared/PageHandler';
import Page from '@jpp/templates/Page';
import { IReduxState } from 'common/redux/createStore';
import { IPageStoreState } from 'common/redux/page/reducer';
import { getPageFromState } from 'common/redux/page/selectors';
import { clearPage, getPage } from 'common/redux/rootActions';
import { EPageType } from 'common/typings/enums';
import {
  TFuncValueVoid,
  TFuncVoid,
  TReduxError,
  TTemplateInitialProps,
} from 'common/typings/types';
import { shouldPageError } from 'common/utils/shared/page';

export interface IDefaultPageProps {
  slug: string;
  error?: TReduxError;
}

export interface IStoreDefaultPageProps {
  page: IPageStoreState;
}

export interface IDispatchDefaultPageProps {
  onClearPage: TFuncVoid;
  onGetPage: TFuncValueVoid<string>;
}

export type TDefaultPage = IDefaultPageProps &
  IStoreDefaultPageProps &
  IDispatchDefaultPageProps;

class DefaultPage extends PureComponent<TDefaultPage> {
  static async getInitialProps({
    query: { slug },
    store,
    res,
  }: TTemplateInitialProps) {
    const dispatch = store.dispatch;

    if (slug) {
      await dispatch(getPage(slug) as any);
    }

    const state: IReduxState = store.getState();
    const page: IPageStoreState = getPageFromState(state);

    await shouldPageError(page, res, dispatch);

    return { slug, page };
  }

  componentDidMount = () => {
    if (this.props.error) {
      this.props.onGetPage(this.props.slug);
    }
  };

  componentWillUnmount = () => {
    this.props.onClearPage();
  };

  render() {
    return (
      <PageHandler {...this.props} pageType={EPageType.Page} template={Page} />
    );
  }
}

const mapDispatchToProps: IDispatchDefaultPageProps = {
  onClearPage: clearPage,
  onGetPage: getPage,
};

export default connect<never, IDispatchDefaultPageProps, IDefaultPageProps>(
  null,
  mapDispatchToProps
)(DefaultPage);
